import { Fragment, FunctionComponent } from 'react';

import { styled, Typography, TooltipProps } from '@mui/material';
import Tooltip from '@/components/molecules/Tooltip';

interface ComponentProps extends Omit<TooltipProps, 'title'> {
  hotkeys: string[];
  offset?: number;
}

const StyledTooltip = styled(Tooltip)(() => ({
  '& .MuiTooltip-tooltip': {
    padding: 0,
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: `${theme.typography.pxToRem(11)}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const HotkeyTooltip: FunctionComponent<ComponentProps> = ({
  hotkeys,
  children,
  offset,
  ...props
}) => (
  <StyledTooltip
    {...props}
    sx={(theme) => ({
      '& .MuiTooltip-tooltip': {
        margin: theme.spacing(offset ?? 1),
      },
    })}
    title={
      <TitleText>
        Hotkey:{' '}
        {hotkeys.map((hotkey, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${hotkey}-${index}`}>{hotkey.toUpperCase()}</Fragment>
        ))}
      </TitleText>
    }
  >
    {children}
  </StyledTooltip>
);

export default HotkeyTooltip;
