import { forwardRef } from 'react';

import { Box, BoxProps, styled } from '@mui/material';

export interface ScrollViewProps extends Omit<BoxProps, 'ref'> {}

const Container = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxHeight: '100%',
  minHeight: 0,
  overflow: 'overlay',
  overflowX: 'hidden',
}));

const ScrollView = forwardRef<HTMLDivElement, ScrollViewProps>((props, ref) => (
  <Container {...props} ref={ref} />
));

export default ScrollView;
