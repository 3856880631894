import { AutocompleteGetTagProps, styled } from '@mui/material';
import { FunctionComponent } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export interface ChipProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background?.default,
  height: theme.spacing(1.5),
  margin: theme.spacing(0.125),
  border: `1px solid ${theme.palette.line?.medium}`,
  borderRadius: theme.spacing(0.125),
  padding: theme.spacing(0, 0.25),
  outline: 0,
  overflow: 'hidden',
  boxSizing: 'content-box',

  '&:hover, &:focus': {
    borderColor: theme.palette.secondary?.dark,
    backgroundColor: theme.palette.secondary?.main,
  },

  span: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  svg: {
    fontSize: theme.spacing(1),
    cursor: 'pointer',
    padding: theme.spacing(0.125),
  },
}));

const Chip: FunctionComponent<ChipProps> = ({ label, onDelete, ...props }) => (
  <Container {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </Container>
);

export default Chip;
