import {
  Fragment,
  FunctionComponent,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import { styled } from '@mui/material';
import classNames from 'classnames';

interface ComponentProps {
  hotkeys: string[];
  label?: string | ReactNode;
  onlyFirst?: boolean;
  show?: boolean;
  disableHover?: boolean;
}

interface TextChunk {
  value: string;
  type: 'underline' | 'regular';
}

const Text = styled('span')(() => ({
  '&.underline': {
    textDecoration: 'underline',
  },
}));

const HotkeyUnderline: FunctionComponent<ComponentProps> = ({
  hotkeys,
  label,
  onlyFirst = false,
  show = false,
  disableHover = false,
}) => {
  const [useUnderline, setUseUnderline] = useState<boolean>(false);

  const texts = useMemo(() => {
    if (!label || typeof label !== 'string') {
      return [];
    }

    if (onlyFirst) {
      hotkeys.forEach((hotkey) => {
        const index = label.indexOf(hotkey);

        const chunks = [
          {
            value: label.slice(0, index),
            type: 'regular',
          },
          {
            value: label.slice(index, index + 1),
            type: 'underline',
          },
          {
            value: label.slice(index + 1),
            type: 'regular',
          },
        ];
        return chunks;
      });
    }

    const chunks: TextChunk[] = [];
    const indexes: number[] = [];
    for (let i = 0; i < label.length; i += 1) {
      hotkeys.forEach((hotkey) => {
        if (label[i].toLowerCase() === hotkey.toLowerCase()) {
          indexes.push(i);
        }
      });
    }
    let index = 0;
    indexes.forEach((i, checkIndex) => {
      const chunk: TextChunk = {
        value: label.slice(index, i),
        type: 'regular',
      };
      const underline: TextChunk = {
        value: label.slice(i, i + 1),
        type: 'underline',
      };

      index = i + 1;
      if (chunk) {
        chunks.push(chunk);
      }
      chunks.push(underline);

      if (checkIndex === indexes.length - 1) {
        chunks.push({
          value: label.slice(i + 1),
          type: 'regular',
        });
      }
    });
    return chunks;
  }, [hotkeys, label, onlyFirst]);

  const hoverEvents = useMemo(() => {
    if (disableHover) {
      return {};
    }

    return {
      onMouseEnter: () => setUseUnderline(true),
      onMouseLeave: () => setUseUnderline(false),
      onFocus: () => setUseUnderline(true),
      onBlur: () => setUseUnderline(false),
    };
  }, [disableHover]);

  return (
    <span {...hoverEvents}>
      {texts.map((chunk, index) => (
        <Fragment key={chunk.value + index.toString()}>
          <Text
            className={classNames({
              underline: (show || useUnderline) && chunk.type === 'underline',
            })}
          >
            {chunk.value}
          </Text>
        </Fragment>
      ))}
    </span>
  );
};

export default HotkeyUnderline;
