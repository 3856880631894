import { FunctionComponent } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import SaveIcon from './icon.svg';

const SaveOutlined: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={SaveIcon}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    {...props}
  />
);

export default SaveOutlined;
