import { FunctionComponent, MouseEventHandler, useRef, useState } from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps as MUITooltipProps,
  useMediaQuery,
} from '@mui/material';

import { Defer } from '@/components/Defer';

export interface TooltipProps extends MUITooltipProps {
  /**
   * On touch devices, fallback to triggering `onClick` instead of `onMouseEnter`.
   * Not all cases need this like when tooltips are shown on elements with click events (e.g. buttons).
   *
   * @default false
   */
  useOnClickForTouch?: boolean;
}

/**
 * Separated from `Tooltip` so we can defer the use of `useMediaQuery`.
 */
const Content: FunctionComponent<TooltipProps> = ({
  children,
  PopperProps = {},
  useOnClickForTouch,
  ...props
}) => {
  const isTouch = useMediaQuery('(pointer: coarse)') && useOnClickForTouch;
  const [open, setOpen] = useState(false);
  const popperRef = useRef<HTMLDivElement>(null);

  const handleClose: MUITooltipProps['onClose'] = () => {
    setTimeout(
      () => {
        setOpen(() => false);
      },
      isTouch ? 800 : 0,
    );
  };
  const handleClick: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    setOpen((o) => !o);
  };
  const handleMouseLeave: MouseEventHandler<HTMLElement> = (e) => {
    const { relatedTarget } = e;

    if (popperRef?.current?.contains(relatedTarget as Node)) {
      return;
    }

    setOpen(() => false);
  };
  const handleMouseOpen: MouseEventHandler<HTMLElement> = () =>
    setOpen(() => true);

  return (
    <MuiTooltip
      {...props}
      disableFocusListener={!isTouch}
      disableTouchListener={isTouch}
      onClick={isTouch ? handleClick : undefined}
      onClose={handleClose}
      onMouseEnter={isTouch ? undefined : handleMouseOpen}
      onMouseLeave={isTouch ? undefined : handleMouseLeave}
      open={open}
      PopperProps={{ ...PopperProps, ref: popperRef }}
    >
      {children}
    </MuiTooltip>
  );
};

const Tooltip: FunctionComponent<TooltipProps> = ({ children, ...props }) => (
  <Defer fallback={children} duration={1000}>
    <Content {...props}>{children}</Content>
  </Defer>
);

export default Tooltip;
