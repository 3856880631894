import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import WaldoIcon from './icon.svg';

const Icon = styled(WaldoIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

const Waldo: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="68"
    height="24"
    viewBox="0 0 68 24"
    {...props}
  />
);

export default Waldo;
