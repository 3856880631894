import { FunctionComponent, ReactNode, useMemo } from 'react';
import { ArrowForwardIosRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonProps,
  ListItem as ListItemMui,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useRouter } from 'next/router';
import { TrackableEvent, TrackableTarget } from '../../lib/trackable';
import { useSetDialog } from '../state/dialog';
import { useTracking } from '../state/tracking';
import HotkeyTooltip from '../ui/HotkeyTooltip';
import HotkeyUnderline from '../ui/HotkeyUnderline';
import CheckoutForm from '@/components/organisms/CheckoutForm';
import { useUser } from '../state/user';
import {
  useInvites,
  useSelectedTeam,
  useSetSelectedTeam,
  useTeams,
} from '../state/teams';
import { useSetColumnTemplateExpanded } from '@/modules/templates/columnTemplate';
import { Link } from '@/components/atoms';

export interface SettingsMenuItemsProps {
  setOpen: (value: boolean) => void;
  useStandaloneDialogs?: boolean;
}

interface WaldoMenu extends Omit<ButtonProps, 'title'> {
  target?: string;
  hotkeys?: string[];
  title: string | ReactNode;
  id: string;
  Component?: FunctionComponent<Omit<WaldoMenu, 'Component'>>;
  hidden?: boolean;
}

const ListItem = styled(ListItemMui)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 1px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    padding: 0,
  },
}));

const MenuItem = styled(Button)(({ theme }) => ({
  flex: 1,
  justifyContent: 'flex-start',
  textAlign: 'left',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(3.5),
  },

  '&:hover': {
    textDecoration: 'underline',
  },
}));

const MenuItemWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing(0.25),
    width: '100%',
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  '&:visited': {
    color: theme.palette.secondary.light,
  },
}));

const WaldoMenuSettingsItems: FunctionComponent<SettingsMenuItemsProps> = ({
  setOpen,
  useStandaloneDialogs = false,
}) => {
  const setDialog = useSetDialog();
  const trackEvent = useTracking();
  const user = useUser();
  const { pathname } = useRouter();
  const isSearchPage = pathname === '/search';
  const { data: teams } = useTeams();
  const { data: invites } = useInvites();
  const selectedTeam = useSelectedTeam();
  const setSelectedTeam = useSetSelectedTeam();
  const setExpanded = useSetColumnTemplateExpanded();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  const waldoMenu: WaldoMenu[] = useMemo(
    () => [
      {
        id: 'pricing',
        title: 'Pricing',
        Component: ({ title, ...item }) => (
          <MenuItem as="span" variant="text" {...item}>
            <StyledLink href="/pricing" variant="body2" color="text.primary">
              {title}
            </StyledLink>
          </MenuItem>
        ),
        hidden: !!user?.subscriptions.some((s) => s.status === 'active'),
      },
      {
        id: 'invite',
        title: 'Invite friends',
        onClick: () => {
          setOpen(false);

          if (small) {
            setExpanded(() => undefined);
          }

          setDialog({ dialog: 'inviteFriends' });
        },
      },
      {
        id: 'teams',
        title: 'Teams',
        onClick: () => {
          setOpen(false);

          if (small) {
            setExpanded(() => undefined);
          }

          if (teams && invites) {
            if (teams.length === 0 && invites.length === 0) {
              setDialog({ dialog: 'firstTeam' });
            } else {
              if (teams.length > 0 && !selectedTeam) {
                setSelectedTeam(teams[0]);
              }
              setDialog({ dialog: 'userTeams' });
            }
          } else {
            setDialog({ dialog: 'firstTeam' });
          }
        },
      },
      {
        onClick: !useStandaloneDialogs
          ? () => {
              setOpen(false);
              setDialog({ dialog: 'feedback' });
            }
          : undefined,
        title: 'Share feedback',
        id: 'feedback',
        href: useStandaloneDialogs ? '/d/feedback' : undefined,
      },
      {
        onClick: () => {
          setOpen(false);
          setDialog({ dialog: 'tutorials' });
        },
        title: 'Tutorials / How to use Waldo',
        id: 'tutorials',
        href: useStandaloneDialogs ? '/d/tutorials' : undefined,
        hidden: !isSearchPage,
      },
      {
        onClick: !useStandaloneDialogs
          ? () => {
              setOpen(false);
              setDialog({ dialog: 'hotkeys' });

              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.VIEW_HOTKEYS,
              });
            }
          : undefined,
        title: 'Keyboard shortcuts',
        href: useStandaloneDialogs ? '/d/hotkeys' : undefined,
        hotkeys: ['H'],
        id: 'hotkeys',
        hidden: !isSearchPage,
      },
      {
        Component: ({ title, ...item }) => (
          <CheckoutForm customerPortal style={{ width: '100%' }}>
            <MenuItem variant="text" type="submit" {...item}>
              <Typography variant="body2" color="text.primary">
                {title}
              </Typography>
            </MenuItem>
          </CheckoutForm>
        ),
        title: 'Manage billing',
        id: 'membership',
        hidden: !user?.subscriptions.find((s) => s.status === 'active'),
      },
      {
        onClick: !useStandaloneDialogs
          ? () => {
              setOpen(false);
              setDialog({ dialog: 'wonder' });
            }
          : undefined,
        title: 'Delegate search to Wonder',
        id: 'wonder',
        href: useStandaloneDialogs ? '/d/wonder' : undefined,
        hidden: !isSearchPage,
      },
      {
        onClick: !useStandaloneDialogs
          ? () => {
              setOpen(false);
              setDialog({ dialog: 'usabilitySettings' });
            }
          : undefined,
        title: 'Usability settings',
        id: 'usabilitySettings',
        href: useStandaloneDialogs ? '/d/usabilitySettings' : undefined,
        hidden: !isSearchPage,
      },
      {
        href: 'https://www.notion.so/waldofyi/Waldo-FAQ-7d962fb15733437cb62eb53fce5d3da3',
        target: '_blank',
        title: 'FAQ',
        id: 'faq',
      },
    ],
    [setDialog, setOpen, trackEvent],
  );

  return (
    <>
      {waldoMenu.map(({ title, Component, hidden, ...item }) => {
        if (hidden) {
          return null;
        }

        return (
          <ListItem key={item.id}>
            {item.hotkeys ? (
              <HotkeyTooltip hotkeys={item.hotkeys}>
                <MenuItemWrapper>
                  <MenuItem variant="text" {...item}>
                    <Typography variant="body2">
                      <HotkeyUnderline hotkeys={item.hotkeys} label={title} />
                    </Typography>
                  </MenuItem>
                  {small && <ArrowForwardIosRounded />}
                </MenuItemWrapper>
              </HotkeyTooltip>
            ) : (
              <MenuItemWrapper>
                {Component ? (
                  <Component title={title} {...item} />
                ) : (
                  <MenuItem variant="text" {...item}>
                    <Typography variant="body2">{title}</Typography>
                  </MenuItem>
                )}
                {small && <ArrowForwardIosRounded />}
              </MenuItemWrapper>
            )}
          </ListItem>
        );
      })}
    </>
  );
};

export default WaldoMenuSettingsItems;
