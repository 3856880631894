import { getLocalStorageItem } from '@/ext/lib/storage';

import { useConnectionIssues } from '../state/connectionIssues';
import { useUserLoadable } from '../state/user';

export const useShowUserRelatedContent = (disable?: boolean): boolean => {
  const loadable = useUserLoadable();
  const user = loadable.valueMaybe();
  const isAuthenticated = !!getLocalStorageItem('isAuthenticated');
  const loaded = loadable.state !== 'loading';
  const connectionIssues = useConnectionIssues();
  if (disable) {
    return false;
  }

  return loaded ? !!user && !connectionIssues : isAuthenticated;
};
