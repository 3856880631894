import { forwardRef, HTMLProps, useMemo } from 'react';
import dayjs from 'dayjs';
import { useUser } from '@/ext/app/state/user';
import { wasSubscriber } from '@/modules/payments/utils/wasSubscriber';
import { SubscriptionType } from '@/ext/types';

export interface CheckoutFormProps extends HTMLProps<HTMLFormElement> {
  priceId?: string;
  customerPortal?: boolean;
  teamId?: string;
  additionalLicenses?: {
    quantity: number;
    priceId: string;
  };
}

const CheckoutForm = forwardRef<HTMLFormElement, CheckoutFormProps>(
  (
    {
      children,
      priceId,
      teamId,
      customerPortal = false,
      additionalLicenses,
      ...props
    },
    ref,
  ) => {
    const user = useUser();

    const isCheckout = useMemo(
      () =>
        !customerPortal &&
        (!wasSubscriber(user) ||
          dayjs().diff(user?.subscriptionEndDate, 'day') > 0 ||
          !!teamId ||
          user?.subscription === SubscriptionType.lite),
      [user, customerPortal, teamId],
    );

    if (!user) {
      return <>{children}</>;
    }

    const action = isCheckout
      ? '/v1/payments/checkout'
      : '/v1/payments/customer-portal';

    const checkoutFields = (
      <>
        <input
          hidden
          name="successUrl"
          value={typeof window !== 'undefined' ? window.location.href : ''}
        />
        {teamId && <input hidden name="teamId" value={teamId} type="number" />}
        {additionalLicenses?.quantity && (
          <input
            hidden
            name="additionalLicenseQuantity"
            value={String(additionalLicenses.quantity)}
          />
        )}
        {additionalLicenses?.priceId && (
          <input
            hidden
            name="additionalLicensePriceId"
            value={String(additionalLicenses.priceId)}
          />
        )}
      </>
    );

    const customerPortalFields = (
      <>
        {priceId && (
          <input hidden name="flow" value="subscription_update_confirm" />
        )}
        <input
          hidden
          name="returnUrl"
          value={typeof window !== 'undefined' ? window.location.href : ''}
        />
      </>
    );

    return (
      <form
        {...props}
        ref={ref}
        action={`${process.env.NEXT_PUBLIC_API_BASE}${action}`}
        method="POST"
      >
        {priceId && <input hidden name="priceId" value={priceId} />}
        {isCheckout ? checkoutFields : customerPortalFields}
        {children}
      </form>
    );
  },
);

export default CheckoutForm;
