import { FunctionComponent } from 'react';

import { Switch, SwitchProps } from '@mui/material';
import { useRecoilValueLoadable } from 'recoil';

import {
  extensionInstalledState,
  useDefaultSearchEngine,
  useToggleDefaultSearchEngine,
} from '../state/engine';
import withSuspense from '../utils/withSuspense';

export interface SourceEngineSwitchProps extends SwitchProps {}

const SourceEngineSwitch: FunctionComponent<SourceEngineSwitchProps> = (
  props,
) => {
  const toggleSourceEngine = useToggleDefaultSearchEngine();
  const sourceEngine = useDefaultSearchEngine();
  const extensionInstalled = useRecoilValueLoadable(
    extensionInstalledState,
  ).valueMaybe();

  return (
    <Switch
      {...props}
      disabled={!extensionInstalled}
      checked={sourceEngine === 'bing'}
      onChange={toggleSourceEngine}
    />
  );
};

export default withSuspense(SourceEngineSwitch, {
  fallback: null,
});
