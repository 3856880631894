import { FunctionComponent, ReactNode, useState } from 'react';
import { useTimeout } from 'usehooks-ts';

export const Defer: FunctionComponent<{
  override?: boolean;
  fallback?: ReactNode;
  duration?: number;
}> = ({ override = false, duration = Infinity, fallback = null, children }) => {
  const [visible, setVisible] = useState(false);

  useTimeout(() => setVisible(true), duration);

  if (visible || override) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
