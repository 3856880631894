import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  styled,
} from '@mui/material';
import classNames from 'classnames';
import NextLink from 'next/link';
import { FunctionComponent } from 'react';
import { UrlObject } from 'url';

export interface LinkProps extends Omit<MuiLinkProps, 'href'> {
  href: string | UrlObject;
  noVisited?: boolean;
}

const StyledLink = styled(MuiLink)(({ theme }) => ({
  '&.noVisited': {
    color: theme.palette.primary.main,
  },
}));

const Link: FunctionComponent<LinkProps> = ({
  href,
  className,
  noVisited,
  ...props
}) => (
  <NextLink href={href} passHref>
    <StyledLink
      underline="hover"
      className={classNames(className, { noVisited })}
      {...props}
    />
  </NextLink>
);

export default Link;
